import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import "./BridgeStyles.css";
import movingImageSrc from "./images/pluglogobg.png"; // Ensure you have this image file

const networks = {
  ETHEREUM: {
    id: "eth",
    name: "Ethereum",
    rpcUrl: "https://mainnet.infura.io/v3/7263b87e7c1040f8a8df22cef3f69def",
    tokenAddress: "0x231A6BD8eB88Cfa42776B7Ac575CeCAf82bf1E21",
    chainId: "0x1", // Ethereum Mainnet
  },
  BASE: {
    id: "base",
    name: "Base",
    rpcUrl: "https://mainnet.base.org",
    tokenAddress: "0xDe737ab7b9DfcB6B1A3b4bab5365517e3cf15e75",
    chainId: "0x2105", // Base Mainnet
  },
};

const ethTokenAddress = "0x231A6BD8eB88Cfa42776B7Ac575CeCAf82bf1E21"; // Ethereum Mainnet Beraplug
const baseTokenAddress = "0xDe737ab7b9DfcB6B1A3b4bab5365517e3cf15e75"; // Base Mainnet Beraplug
const baseChainId = 184; // LayerZero ID for Base Mainnet
const ethChainId = 101; // LayerZero ID for Ethereum Mainnet

const tokenAbi = [
  "function balanceOf(address owner) view returns (uint256)",
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function estimateSendFee(uint16 dstChainId, bytes32 toAddress, uint256 amount, bool useZro, bytes calldata adapterParams) external view returns (uint256 nativeFee, uint256 zroFee)",
  "function sendFrom(address _from, uint16 _dstChainId, bytes32 _toAddress, uint256 _amount, uint256 _minAmount, tuple(address refundAddress, address zroPaymentAddress, bytes adapterParams) _callParams) external payable",
];

const DevBridge = () => {
  const { address, isConnected } = useAccount();

  const adapterParams = ethers.solidityPacked(
    ["uint16", "uint256"],
    [1, 500000]
  );
  const minAmount = ethers.parseUnits("8", 18);

  const [sourceNetwork, setSourceNetwork] = useState(networks.ETHEREUM.id);
  const [destinationNetwork, setDestinationNetwork] = useState(
    networks.BASE.id
  );

  const [balances, setBalances] = useState({ eth: null, base: null });
  const [amountToBridge, setAmountToBridge] = useState("");

  const [isTransactionPending, setIsTransactionPending] = useState(false);
  const [images, setImages] = useState([]); // Stores current images to animate
  const [persistentImages, setPersistentImages] = useState([]); // Stores persistent images
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false);
  const [transactionError, setTransactionError] = useState(false);

  // Function to generate random image properties
  const generateRandomImage = () => {
    return {
      id: Math.random(),
      size: Math.random() * 20 + 20, // Random size between 20px and 40px
      speed: Math.random() * 5 + 3, // Random speed between 3s and 8s
      rotationSpeed: Math.random() * 5 + 3, // Random rotation speed
      topPosition: Math.random() * 60 + 10, // Random top position between 10% and 70%
    };
  };

  useEffect(() => {
    const spawnImages = () => {
      const newImages = [];
      for (let i = 0; i < 15; i++) {
        newImages.push(generateRandomImage());
      }
      setImages(newImages);
    };
    spawnImages();
    const interval = setInterval(spawnImages, 30000); // Every 30 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleSourceChange = (e) => {
    const selected = e.target.value;
    setSourceNetwork(selected);

    if (selected === destinationNetwork) {
      setDestinationNetwork(
        selected === networks.ETHEREUM.id
          ? networks.BASE.id
          : networks.ETHEREUM.id
      );
    }
  };

  const handleDestinationChange = (e) => {
    const selected = e.target.value;
    setDestinationNetwork(selected);

    if (selected === sourceNetwork) {
      setSourceNetwork(
        selected === networks.ETHEREUM.id
          ? networks.BASE.id
          : networks.ETHEREUM.id
      );
    }
  };

  // Fetch balances whenever the user is connected and address is available
  useEffect(() => {
    const fetchBalances = async (addr) => {
      if (!addr) return;
      try {
        const ethProvider = new ethers.JsonRpcProvider(
          networks.ETHEREUM.rpcUrl
        );
        const ethContract = new ethers.Contract(
          networks.ETHEREUM.tokenAddress,
          tokenAbi,
          ethProvider
        );
        const ethBalance = await ethContract.balanceOf(addr);

        const baseProvider = new ethers.JsonRpcProvider(networks.BASE.rpcUrl);
        const baseContract = new ethers.Contract(
          networks.BASE.tokenAddress,
          tokenAbi,
          baseProvider
        );
        const baseBalance = await baseContract.balanceOf(addr);

        setBalances({
          eth: parseFloat(ethers.formatEther(ethBalance)),
          base: parseFloat(ethers.formatEther(baseBalance)),
        });
      } catch (err) {
        console.error("Error fetching balances:", err);
      }
    };

    if (isConnected && address) {
      fetchBalances(address);
    } else {
      setBalances({ eth: null, base: null });
    }
  }, [isConnected, address]);

  const getCurrentNetwork = async () => {
    if (window.ethereum) {
      try {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        return chainId;
      } catch (err) {
        console.error("Error fetching current network:", err);
        return null;
      }
    } else {
      console.error("Ethereum provider is not available.");
      return null;
    }
  };

  // Fee estimation for Base to Ethereum (send BASE to ETH)
  const estimateBaseToEthFees = async (amount) => {
    if (!address) return ethers.parseUnits("0.01", 18);
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(
      baseTokenAddress,
      tokenAbi,
      signer
    );

    try {
      const [nativeFee] = await tokenContract.estimateSendFee(
        ethChainId,
        ethers.zeroPadValue(address, 32),
        ethers.parseEther(amount.toString()),

        false,
        adapterParams
      );
      return nativeFee;
    } catch (error) {
      console.error("Error estimating Base to Ethereum fees:", error);
      return ethers.parseUnits("0.01", 18);
    }
  };

  // Fee estimation for Ethereum to Base (send ETH to BASE)
  const estimateEthToBaseFees = async (amount) => {
    if (!address) return ethers.parseUnits("0.01", 18);
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(
      ethTokenAddress,
      tokenAbi,
      signer
    );

    try {
      const [nativeFee] = await tokenContract.estimateSendFee(
        baseChainId,
        ethers.zeroPadValue(address, 32),
        ethers.parseEther(amount.toString()),
        false,
        adapterParams
      );
      return nativeFee;
    } catch (error) {
      console.error("Error estimating Ethereum to Base fees:", error);
      return ethers.parseUnits("0.01", 18);
    }
  };

  const sendEthtoBase = async (amount) => {
    if (!address) {
      console.error("No address available");
      return;
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(
      networks.ETHEREUM.tokenAddress,
      tokenAbi,
      signer
    );

    try {
      const nativeFee = await estimateEthToBaseFees(amount);
      console.log("nativefee for ETH to Base: ", nativeFee);

      setIsTransactionPending(true);

      // Reset error message state
      setTransactionError(false);

      const tx = await tokenContract.sendFrom(
        address,
        baseChainId,
        ethers.zeroPadValue(address, 32),
        ethers.parseEther(amount.toString()),
        ethers.parseUnits("8", 18),
        {
          refundAddress: address,
          zroPaymentAddress: ethers.ZeroAddress,
          adapterParams: adapterParams,
        },
        { value: nativeFee, gasLimit: 300000 }
      );

      await tx.wait();

      setIsTransactionSuccessful(true);
      setIsTransactionPending(false);
    } catch (error) {
      console.error("Error sending tokens:", error);

      // Reset success message state
      setIsTransactionSuccessful(false);

      setTransactionError(true);
      setIsTransactionPending(false);
    }
  };

  const sendBasetoEth = async (amount) => {
    if (!address) {
      console.error("No address available");
      return;
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(
      networks.BASE.tokenAddress,
      tokenAbi,
      signer
    );

    try {
      const nativeFee = await estimateBaseToEthFees(amount);
      console.log("nativeFee for Base to ETH: ", nativeFee);

      setIsTransactionPending(true);

      // Reset error message state
      setTransactionError(false);

      const tx = await tokenContract.sendFrom(
        address,
        ethChainId,
        ethers.zeroPadValue("0xDe737ab7b9DfcB6B1A3b4bab5365517e3cf15e75", 32), //HARD CODED ADDRESS HERE
        ethers.parseEther(amount.toString()),
        ethers.parseUnits("8", 18),
        {
          refundAddress: address,
          zroPaymentAddress: ethers.ZeroAddress,
          adapterParams: adapterParams,
        },
        { value: nativeFee, gasLimit: 300000 }
      );

      await tx.wait();

      setIsTransactionSuccessful(true);
      setIsTransactionPending(false);
    } catch (error) {
      console.error("Error sending tokens:", error);

      // Reset success message state
      setIsTransactionSuccessful(false);

      setTransactionError(true);
      setIsTransactionPending(false);
    }
  };

  const switchNetwork = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (error) {
      if (error.code === 4902) {
        console.error("Network not available in your wallet.");
      } else {
        console.error("Error switching network:", error);
      }
    }
  };

  const bridgeButton = async () => {
    if (!isConnected || !address) {
      console.error("Wallet not connected");
      return;
    }

    const currentNetwork = await getCurrentNetwork();
    if (sourceNetwork === networks.ETHEREUM.id && currentNetwork !== "0x1") {
      await switchNetwork("0x1");
    } else if (
      sourceNetwork === networks.BASE.id &&
      currentNetwork !== "0x2105"
    ) {
      await switchNetwork("0x2105");
    }

    if (
      sourceNetwork === networks.ETHEREUM.id &&
      destinationNetwork === networks.BASE.id
    ) {
      sendEthtoBase(amountToBridge);
    } else if (
      sourceNetwork === networks.BASE.id &&
      destinationNetwork === networks.ETHEREUM.id
    ) {
      sendBasetoEth(amountToBridge);
    } else {
      console.error("Invalid network combination");
    }
  };

  return (
    <div className="bridge-centered-content">
      {!isConnected ? (
        <p className="connect-wallet-message">
          Please connect your wallet to use the bridge.
        </p>
      ) : (
        <div>
          <div className="bridge-header">
            <h2>DEV BRIDGE, DO NOT USE</h2>
          </div>
          <div className="bridge-menu">
            {/* Floating Images Container */}
            <div className="image-container">
              {images.map((image) => (
                <img
                  key={image.id}
                  src={movingImageSrc}
                  className="moving-image"
                  style={{
                    width: `${image.size}px`,
                    height: `${image.size}px`,
                    top: `${image.topPosition}%`,
                    animation: `moveAcross ${image.speed}s linear infinite, rotate ${image.rotationSpeed}s linear infinite`,
                  }}
                  alt="Moving Logo"
                />
              ))}
            </div>
            <div className="network-card">
              <div className="network-box">
                <select
                  value={sourceNetwork}
                  onChange={handleSourceChange}
                  className="bridge-dropdown"
                >
                  <option value={networks.ETHEREUM.id}>
                    {networks.ETHEREUM.name}
                  </option>
                  <option value={networks.BASE.id}>{networks.BASE.name}</option>
                  <option value="berachain" disabled style={{ color: "gray" }}>
                    Berachain
                  </option>
                </select>
                <p className="network-balance">
                  Balance:{" "}
                  {balances[sourceNetwork] !== null
                    ? balances[sourceNetwork] < 0.01
                      ? "0"
                      : balances[sourceNetwork].toFixed(2)
                    : "Loading..."}
                </p>
              </div>
            </div>
            <div className="arrow">
              {isTransactionPending ? <div className="spinner"></div> : "→"}
            </div>
            <div className="network-card">
              <div className="network-box">
                <select
                  value={destinationNetwork}
                  onChange={handleDestinationChange}
                  className="bridge-dropdown"
                >
                  <option value={networks.ETHEREUM.id}>
                    {networks.ETHEREUM.name}
                  </option>
                  <option value={networks.BASE.id}>{networks.BASE.name}</option>
                  <option value="berachain" disabled style={{ color: "gray" }}>
                    Berachain
                  </option>
                </select>
                <p className="network-balance">
                  Balance:{" "}
                  {balances[destinationNetwork] !== null
                    ? balances[destinationNetwork] < 0.01
                      ? "0"
                      : balances[destinationNetwork].toFixed(2)
                    : "Loading..."}
                </p>
              </div>
            </div>
          </div>

          <div className="bridge-input-container">
            <div className="input-card">
              <input
                type="number"
                value={amountToBridge}
                onChange={(e) => setAmountToBridge(e.target.value)}
                placeholder="Enter amount"
                className="bridge-input"
              />
              <button
                className="max-button"
                onClick={() => setAmountToBridge(balances[sourceNetwork] || 0)}
              >
                Max
              </button>
            </div>
          </div>

          <button className="bridge-button" onClick={bridgeButton}>
            {isTransactionPending ? <div className="spinner"></div> : "Bridge"}
          </button>

          {/* Success and Error Messages */}
          <div>
            {isTransactionSuccessful && (
              <div className="success-message">Transaction Successful!</div>
            )}
            {transactionError && (
              <div className="error-message">
                Transaction Failed. Please try again.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DevBridge;
