// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/NavigationBar";
import Home from "./pages/Home";
import Claim from "./pages/Claim";
import Swap from "./pages/Swap";
import Stats from "./pages/Stats";
import Bridge from "./pages/Bridge";
import DevBridge from "./pages/DevBridge"; // Import the new page

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  sepolia,
  berachainTestnetbArtio,
} from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains: [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    sepolia,
    berachainTestnetbArtio,
  ],
  ssr: true, // If your dApp uses server side rendering (SSR)
});
const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/claim" element={<Claim />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/bridge" element={<Bridge />} />
              <Route path="/devbridge" element={<DevBridge />} />{" "}
              {/* Add new route */}
            </Routes>
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
